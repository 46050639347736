import React from 'react'

import FileUpload from './FileUpload'


export default function EmailAnalyzer() {

  return (
    <>
      <FileUpload />
    </>
  )
}
